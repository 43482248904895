<template>
    <div>
        <title-bar :title="$t('navigations.extractions')" />
        <columns-toggle
            :columns-array="defaultColumns"
            :action="showColumnsModal"
            :page="page"
            @clearAction="showColumnsModal = false"
            @init="beforeSetColumns"
            @columnsChange="setColumns"
        />
        <div v-if="rows" class="custom-table">
            <advanced-filter
                :fields-array="filterFields"
                :action="showFilterModal"
                @clearAction="showFilterModal = false"
                @paramsChange="setAdvancedParams"
            />
            <table-actions
                :actions="tableActions"
                :addBtnText="$t('messages.addNew')"
                :addBtnLink="$helper.getAddUrl('extractions')"
                :option="filterOption"
                :options="filterOptions"
                @onSearchInput="inputChange"
                @perPageChange="perPageChange"
                @columnsChange="showColumnsModal = true"
                @filterChange="showFilterModal = true"
                :defaultPerPage="perPage"
            />
            <table-lite
                :is-slot-mode="true"
                :is-loading="isLoading"
                :columns="columns"
                :rows="rows"
                :sortable="sortable"
                @do-search="setSort"
                @is-finished="isLoading = false"
            >
                <template v-slot:image="data">
                  <zoom-on-hover-image v-if="data.value.file && data.value.file.url" :src="data.value.file.url" align="left"></zoom-on-hover-image>
                </template>
                <template v-slot:vendor="data">
                    {{
                        data.value.vendor.name
                    }}
                </template>
                <template v-slot:navisionItem="data">
                  <div v-if="data.value.navisionItem">
                    {{ data.value.navisionItem.number }}
                  </div>
                  <div v-else>-</div>
                </template>
                <template v-slot:description="data">
                  <div v-if="data.value.description">
                    {{ $helper.truncate(data.value.description) }}
                  </div>
                  <div v-else>-</div>
                </template>
                <template v-slot:description2="data">
                  <div v-if="data.value.description2">
                    {{ $helper.truncate(data.value.description2) }}
                  </div>
                  <div v-else>-</div>
                </template>
                <template v-slot:handtool="data">
                  <div v-if="data.value.handtool">
                    {{ data.value.handtool.number }}
                  </div>
                  <div v-else>-</div>
                </template>
                <template v-slot:insertedBy="data">
                  <div v-if="data.value.insertedBy">
                    {{ data.value.insertedBy.givenName + " " + data.value.insertedBy.familyName }}
                  </div>
                  <div v-else>-</div>
                </template>
                <template v-slot:actions="data">
                    <div class="just-buttons">
                        <edit-btn v-if="canUpdate" :path="$helper.getEditUrl('extractions', data.value.id)" />
                        <delete-btn v-if="canDelete" @pressDelete="deleteItem(data.value['@id'])" />
                    </div>
                </template>
            </table-lite>
            <table-pagination
                v-model="currentPage"
                :total="pages"
                :per-page="perPage"
                @input="onChangePage"
            />
        </div>

    </div>
</template>

<script>
import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import ListUtils from "@/mixins/ListUtils";
import TableLiteUtils from "@/mixins/TableLiteUtils";
import ColumnsToggle from "@/components/Table/ColumnsToggle.vue";
import ZoomOnHoverImage from "@/components/ZoomOnHoverImage.vue";

export default {
    name: "Extractions",
    components: {
      ColumnsToggle,
      TableLite,
      TablePagination,
      DeleteBtn,
      TableActions,
      EditBtn,
      TitleBar,
      AdvancedFilter,
      ZoomOnHoverImage
    },
    mixins: [ResourceUtils, ListUtils, TableLiteUtils],
    created() {
        this.setPermissions()
        this.perPage = this.getTableLiteSettingsForPageAndKey(this.page, 'perPage')
        this.load()
    },
    methods: {
        setColumns(data) {
          this.columns = data
          this.setTableLiteSettings(this.page, {columns: this.columns})
        },
        beforeSetColumns() {
          const columns = this.getTableLiteSettingsForPageAndKey(this.page, 'columns')
          if (columns && columns.length > 0) {
            this.defaultColumns.forEach(element => {
              if (columns.includes(element.field)) {
                element.enabled = true
              } else {
                element.enabled = false
              }
            })
          }
        },
        setAdvancedParams(data) {
            this.advancedParams = data
            this.load()
        },
        load() {
            const params = {
                itemsPerPage: this.perPage,
                page: this.currentPage,
                itemCategory: 'EXTRACTION'
            };
            params["order[" + this.sortable.order + "]"] = this.sortable.sort;
            if (this.search && this.search.length) {
                params.search = this.search;
            }
            this.advancedParams.forEach(element => {
                if (element.value !== '' && element.value !== null) {
                    params[element.name] = element.value
                }
            })
            this.$Items.getCollection({params}, 'extraction_list').then((response) => {
                this.pages = response.data["hydra:totalItems"];
                this.rows = response.data["hydra:member"];
            });
        },
        setPermissions() {
          if (this.$helper.userCapability('UPDATE', 'ITEMS') || this.$helper.userCapability('CREATE', 'EXTRACTIONS')) {
            this.tableActions.push('addBtn')
          }
          this.canUpdate = this.$helper.userCapability('UPDATE', 'ITEMS') || this.$helper.userCapability('UPDATE', 'EXTRACTIONS')
          this.canDelete = this.$helper.userCapability('UPDATE', 'ITEMS') || this.$helper.userCapability('DELETE', 'EXTRACTIONS')
        },
        deleteItem(url) {
            this.deleteByUrl(this.$Skills, url, this.$t('item.deleted'), null, this.load, this.error);
        },
        onChangePage(page) {
          this.currentPage = page;
          this.load();
        },
        perPageChange(num) {
          this.currentPage = 1;
          this.perPage = num;
          this.setTableLiteSettings(this.page, {perPage: this.perPage})
          this.load();
        },
        inputChange(value) {
          this.currentPage = 1;
          this.search = value;
          this.load();
        },
        setSort(offset, limit, order, sort) {
          this.sortable.order = order;
          this.sortable.sort = sort;

          if (order === 'navisionItem' || order === 'handtool') {
            this.sortable.order = order + '.number';
          }

          this.load();
        },
    },
    data() {
        return {
            search: "",
            page: 'extractions',
            isLoading: false,
            perPage: this.$store.state.defaultPerPage,
            currentPage: 1,
            pages: 0,
            showFilterModal: false,
            sortable: {
                order: "id",
                sort: "asc",
            },
            canUpdate: false,
            canDelete: false,
            tableActions: ['search', 'perPage','advancedFilter', 'columnsBtn'],
            filterFields: [
                {
                    label: "forms.navisionItem",
                    name: "navisionItem",
                    value: null,
                    type: "customSelect",
                    resource: this.$Items,
                    resourceType: "item",
                    entityType: "NAVISION_ITEM"
                },
                {
                    label: "forms.handtool",
                    name: "handtool",
                    value: null,
                    type: "customSelect",
                    resource: this.$Items,
                    resourceType: "item",
                    entityType: "TOOL",
                    categoryName: "Handtool"
                },
                {
                    label: "forms.insertedBy",
                    name: "insertedBy",
                    value: null,
                    type: "customSelect",
                    resource: this.$Users,
                    resourceType: "employee",
                },
            ],
            advancedParams: [],
            showColumnsModal: false,
            columns: [],
            defaultColumns: [
                {
                    label: this.$t("forms.image"),
                    field: "image",
                    sortable: false,
                    enabled: true,
                },
                {
                    label: this.$t("forms.navisionItem"),
                    field: "navisionItem",
                    sortable: true,
                    enabled: true,
                },
                {
                    label: this.$t("forms.handtool"),
                    field: "handtool",
                    sortable: true,
                    enabled: true,
                },
                {
                    label: this.$t("forms.description2"),
                    field: "description2",
                    sortable: false,
                },
                {
                    label: this.$t("forms.insertedBy"),
                    field: "insertedBy",
                    sortable: true,
                    enabled: true,
                },
                {
                    label: this.$t("forms.actions"),
                    field: "actions",
                    sortable: false,
                    width: "80px",
                    enabled: true,
                },
            ],
            rows: null
        };
    },
};
</script>

